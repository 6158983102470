import React from "react";
import styles from '../stylesheets/landing/landingLayout.module.scss';
import bgAnimations from '../stylesheets/landing/backgroundAnimation.module.scss';

function MenuOption(props) {
  return (
    <div className={styles.menu_option}>
      <a href={props.link}>
        <div className={styles.menu_option__item}>
          <h3>{props.name}</h3>
        </div>
      </a>
    </div>
  );
}

function MenuBar() {
  return (
    <div className={styles.menu_bar}>
      <div className={styles.menu_bar__logo}>
        <img src="/graphics/EcoBankLogo.webp" alt="logo" />
      </div>
      <div className={styles.menu_bar__menu}>
        <MenuOption name="Our Mission" link="#our-mission" />
        <MenuOption name="About us" link="#about" />
        <MenuOption name="Contact" link="#contact" />
        <MenuOption name="API" link="#api-access" />
        <MenuOption name="Sign in" link="sign-in" />
      </div>
    </div>
  );
}

function Landing() {
  console.log(styles)
  return (
    <div className={styles.landing_page}>
      <MenuBar />
      <div className={styles.landing_content}>

        <div className={styles.tile}>
          <div className={`${styles.tile__background} ${bgAnimations.bg_gradient}`}></div>
          <div className={styles.tile__content}>
            <h1 className={`ecoline ${styles.hugetext} ${styles.slide_up}`} >The most powerful banking service on the planet Earth</h1>
            <h1 className={`${styles.hugetext} ${styles.slide_up} ${styles.delayed1}`}>We're not just a bank.</h1>
            <h1 className={`${styles.slide_up} ${styles.delayed3}`} >We're a movement towards a greener, more sustainable future.</h1>
          </div>
        </div>

        <div className={`${styles.tile} ${styles.video_widget}`} id="our-mission">
          <div className={styles.video_wrapper}>
            <video autoPlay muted loop className={styles.ecoVideo}>
              <source src="./graphics/climate_change_background.mp4" type="video/mp4" />
            </video>
          </div>
          <div className={`${styles.tile__description} ${styles.description_wrapper} `}>
            <h1 className={`ecoline ${styles.mediumtext}`}>OUR MISSION</h1>
            <div className={`${styles.description} ${styles.italic}`}> 
              "In Eco Bank we believe in banking with an unwavering commitment to environmental sustainability. Our mission centers on reducing the carbon dioxide footprint associated with financial services. We leverage renewable energy, promote digital banking for reduced paper usage, invest in environmental projects, and offer eco-friendly financial products. Through education and community involvement, we strive to make every financial decision a step towards a greener, more sustainable future."
            </div>
          </div>
        </div>

        <div className={styles.tile} id="about">
          <div className={styles.tile__description}>
            <h1 className={`ecoline ${styles.mediumtext}`}>ABOUT US</h1>
            <div className={styles.description}> 
              We are a team of passionate professionals, united by our commitment to ethical banking practices that prioritize the health of our planet. Our diverse backgrounds in finance, technology, and environmental science enable us to create innovative, green financial solutions.
            </div>
          </div>
        </div>

        <div className={styles.tile} id="contact">
          <div className={styles.tile__description}>
            <h1 className={`ecoline ${styles.mediumtext}`}>CONTACT</h1>
            <div className={styles.description}> 
              <p><strong>Address:</strong> 123 Finance Street, Moneyville, MV 45678</p>
              <p><strong>Phone:</strong> (123) 456-7890</p>
              <p><strong>Email:</strong> <a href="mailto:contact@mockbank.com">contact@ecobank.com</a></p>
            </div>
          </div>
        </div>

        <div className={styles.tile} id="api-access">
          <div className={styles.tile__description}>
            <h1 className={`ecoline ${styles.mediumtext}`}>API for Businesses</h1>
            <div className={styles.description}> 
              <p>Our API allows you to integrate our services into your application. We offer a variety of endpoints for you to access our banking services. </p>
              <p>For more information, please contact. </p>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Landing;
