import { Link } from "react-router-dom";

import styles from '../stylesheets/landing/authorizationLayout.module.scss';
import bgAnimations from '../stylesheets/landing/backgroundAnimation.module.scss';

function SignUp() {
    return (
        <div className={styles.auth_page}>
            <div className={bgAnimations.bg_gradient_2}></div>
            <div className={styles.auth_page__form}>
                <Link to="/">
                    <img src="./graphics/EcoBankLogo.webp" alt="logo" />
                </Link>
                <h1 className="ecoline">Sign Up</h1>
                <form>
                    <p>
                        <input className={styles.auth_page__input} type="email" id="email" placeholder="Email"/>
                    </p>
                    <p>
                        <input className={styles.auth_page__input} type="password" id="password" placeholder="Password"/>
                    </p>
                    <p>
                        <input className={styles.auth_page__input} type="password" id="confirmPassword" placeholder="Confirm Password"/>
                    </p>

                    <p>
                        <label>
                            <input type="checkbox" id="terms" />
                            I agree to the terms and conditions
                        </label>
                    </p>

                    <button className={styles.auth_page__submit} type="submit">Sign Up</button>

                    <p>
                        Already have an account? <Link className="auth-page__link fancy-link" to="/sign-in">Sign in</Link>
                    </p>
                </form>
            </div>
        </div>
    );
}

export default SignUp;
