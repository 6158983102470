import React from 'react';
import styles from '../../stylesheets/desktop/bankingLayout.module.scss';

function Chats() {
    return (
        <div className={styles.banking_view}>
            <div className={styles.banking_view__contents_table}>
                
            </div>
        </div>
    );
}

export default Chats;
