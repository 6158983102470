import React from 'react';

import CO2Chart from './CO2Chart.js';

import styles from '../../stylesheets/desktop/bankingLayout.module.scss';
import inputs from '../../stylesheets/desktop/inputs.module.scss';

export function UserBalance() {
    return (
        <>
            <div className={styles.user_balance}>
                <div>
                    <h1>Balance</h1>
                </div>
                <div>
                    <h1 className={styles.user_balance__balance}>80.04€</h1>
                </div>
            </div>
            <div className={styles.user_balance__iban}>
                <h2>IBAN: IE12 BOFI 9000 0112 3456 78</h2> <button className={`${inputs.standard_button} ${inputs.standard_button__copy}`}>Copy</button>
            </div>
        </>
    )
}

function Transaction(props) {
    return (
        <div className={styles.transactions__single_transaction}>
            <div>{ props.name }</div>
            <div>501 gCO&#8322; &nbsp; -8.99</div>
        </div>
    )
}

function Transactions() {
    return (
        <>
            <h1>Transactions</h1>
            <Transaction name="Kolacja z miesem"/>
            <Transaction name="Mieszkanie" />
            <Transaction name="Kolacja z warzywami" />
            <Transaction name="Czynsz" />
        </>
    )
}

export function EmissionIndicator (props) {
    const emission_percentage = Math.round(props.emmited / props.total * 100);
    return (
        <div className={styles.emission_indicator}>
            <div className={styles.emission_indicator__description}>
                <div>{ emission_percentage }%</div>
                <div className={styles.emission_indicator__allocation}>Your CO2 allocation per month: { props.total } gCO&#8322;</div>
            </div>
            <div className={styles.emission_indicator__placeholder}>
                <div style={{ width: `${emission_percentage}%` }}className={styles.emission_indicator__infill}>{ props.emmited } gCO&#8322;</div>
            </div>
        </div>
    )
}

function Banking() {
    return (
        <div className={styles.banking_view}>
            <h1 className={styles.fat_header}>Your Balance</h1>
            <div className={styles.banking_view__contents_table}>
                <div className={styles.banking_view__column}>
                    <UserBalance />
                    <Transactions />
                </div>
                <div className={styles.banking_view__column}>
                    <h1>Your Emission</h1>
                    <EmissionIndicator emmited={1000} total={1200} />
                    <CO2Chart />
                </div>
            </div>
        </div>
    );
}

export default Banking;
