import React from 'react';
import styles from '../../stylesheets/desktop/bankingLayout.module.scss';
import stylesBlink from '../../stylesheets/desktop/blink.module.scss';

function Blink() {
    const [visibility, setVisibility] = React.useState(false);

    return (
    <>
        { 
            visibility && (<div className={stylesBlink.accept_overlay}>
                <div className={stylesBlink.accept_overlay__prompt}>
                    <div>
                        <h1>Payment request</h1>
                        <h3>430 € / 400 gCO&#8322;</h3>
                        <h4>Do you want to accept payment request from <span className={stylesBlink.accept_overlay__name}>John Doe</span>?</h4>
                    </div>
                    <div className={stylesBlink.accept_overlay__buttons}>
                        <button onClick={() => setVisibility(false)} className={stylesBlink.accept_overlay__buttons__decline}>Decline</button>
                        <button className={stylesBlink.accept_overlay__buttons__accept}>Accept</button>
                    </div>
                </div>
            </div>)
        }
        <div className={styles.banking_view}>
            <h1 className={styles.fat_header}>ecoBLINK</h1>
            <div className={stylesBlink.blink_view}>
                <div className={stylesBlink.blink_view__code_infill}>
                    <div className={stylesBlink.blink_view__code}>
                        <div onClick={() => setVisibility(true)}>
                            <h1 className={stylesBlink.blink_code}>632 022</h1>
                            <h2>0:29</h2>
                        </div>
                    </div>
                    <div>
                        <div className={stylesBlink.blink_view__indicator}>
                            <div style={{ width: `${93}%`}} className={stylesBlink.blink_view__indicator_bar}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
}

export default Blink;

// Wartość transakcji, stan przed, stan po.