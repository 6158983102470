import React from 'react';
import styles from '../../stylesheets/desktop/bankingLayout.module.scss';
import historyStyles from '../../stylesheets/desktop/history.module.scss';

function HistoryItem(props) {
    const amount = props.amount;

    let ammountClass = styles.neutral;

    if (amount > 0) {
        ammountClass = styles.positive;
    } else if (amount < 0) {
        ammountClass = styles.negative;
    }

    return (
        <div className={historyStyles.history_element}>
            <div className={historyStyles.history_element__name}>
                <h1>Andrzej Kowalski</h1>
                <h2>Za kurs</h2>
            </div>
            <div className={historyStyles.history_element__date_cost}>
                <div className={historyStyles.history_element__date}>
                    <p>08.09.2021</p>
                </div>
                <div className={historyStyles.history_element__cost}>
                    <p>420 gCO&#8322;</p>
                    <p className={ammountClass}>{amount}$</p>
                </div>
            </div>
        </div>
    )
}

function History() {
    // This link is data matrix code
    return (
        <div className={styles.banking_view}>
            <h1 className={styles.fat_header}>Transaction history</h1>
            <div className={styles.banking_view__contents_table}>
                <div className={`${historyStyles.history_container} ${styles.banking_view__column}`}>
                    <HistoryItem amount={-10} />
                    <HistoryItem amount={10} />
                    <HistoryItem amount={0} />
                    <HistoryItem amount={-10} />
                    <HistoryItem amount={-10} />
                    <HistoryItem amount={10} />
                    <HistoryItem amount={0} />
                    <HistoryItem amount={-10} />
                    <HistoryItem amount={-10} />
                    <HistoryItem amount={10} />
                    <HistoryItem amount={0} />
                    <HistoryItem amount={-10} />
                    <HistoryItem amount={-10} />
                    <HistoryItem amount={10} />
                    <HistoryItem amount={0} />
                    <HistoryItem amount={-10} />
                </div>

                <div className={styles.banking_view__column}>
                    <h1>Transaction details</h1>
                    <h2>Andrzej Kowalski</h2>
                    <h3>Sender IBAN: IE12 BOFI 9000 0112 3456 78</h3>
                    <h3>Date: 08.09.2021</h3>
                    <h3>Balance after transaction: 88.50$</h3>
                    <h1 className="hugetext ecoline right">Emission: 420 gCO&#8322;</h1>
                    <h1 className="hugetext ecoline right">Amount: 8.50$</h1>
                    
                </div>
            </div>
        </div>
    );
}

export default History;
