import React from 'react';
import styles from '../../stylesheets/desktop/bankingLayout.module.scss';

function Emission() {
    return (
        <div className={styles.banking_view}>
            <h1 className={styles.fat_header}>Emissions trading</h1>
            <div className={styles.banking_view__contents_table}>
                
            </div>
        </div>
    );
}

export default Emission;
