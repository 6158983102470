import { Link, useNavigate } from "react-router-dom";

import styles from '../stylesheets/landing/authorizationLayout.module.scss';
import bgAnimations from '../stylesheets/landing/backgroundAnimation.module.scss';

function SignIn() {
    const navigate = useNavigate();
    return (
        <div className={styles.auth_page}>
            <div className={bgAnimations.bg_gradient_2}></div>
            <div className={styles.auth_page__form}>
                <Link to="/">
                    <img src="./graphics/EcoBankLogo.webp" alt="logo" />
                </Link>
                <h1 className="ecoline">Sign In</h1>
                <form>
                    <p >
                        <input className={styles.auth_page__input} type="email" id="email" placeholder="Email"/>
                    </p>
                    <p >
                        <input className={styles.auth_page__input} type="password" id="password" placeholder="Password"/>
                    </p>

                    <button className={styles.auth_page__submit} type="submit" onClick={() => navigate("/panel")}>Sign In</button>
                    
                    <p className="auth-page__message">
                        You don't have an account yet? <Link className="auth-page__link fancy-link" to="/sign-up">Sign Up</Link>
                    </p>
                </form>
            </div>
        </div>
    );
}

export default SignIn;
