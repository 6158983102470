import React from 'react';
import styles from '../../stylesheets/desktop/bankingLayout.module.scss';
import transferStyles from '../../stylesheets/desktop/transfer.module.scss';
import inputs from '../../stylesheets/desktop/inputs.module.scss';

import { UserBalance } from './Banking';

function Transfers() {
    // This link is data matrix code
    //const mockDataMatrixCode = "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e8/Datamatrix.svg/768px-Datamatrix.svg.png";
    const mockDataMatrixCode = "https://upload.wikimedia.org/wikipedia/commons/thumb/2/20/Azteccodeexample.svg/1200px-Azteccodeexample.svg.png"
    return (
        <div className={styles.banking_view}>
            <h1 className={styles.fat_header}>Financial transfer</h1>
            <div className={styles.banking_view__contents_table}>
                <div className={styles.banking_view__column}>
                    <h1>Funds transfer</h1>
                    <UserBalance />
                    <form className={inputs.form}>
                        <p>
                            <input style={{ width: '49%' }} className={inputs.standard_input} type="text" placeholder="Recipient first name" />
                            <input style={{ width: '49%', marginLeft: '2%'}} className={inputs.standard_input} type="text" placeholder="Recipient last name" />
                        </p>
                        <p>
                            <input className={inputs.standard_input} type="text" placeholder="Receipient IBAN" />
                        </p>
                        <p>
                            <input className={inputs.standard_input} type="text" placeholder="Transfer title" />
                        </p>
                        { /*
                        <p>
                            <input className={inputs.standard_input} type="text" placeholder="Ammount" />
                        </p>
                        <p className={inputs.align_right}>
                            <button className={` ${inputs.standard_button} ${inputs.wide} `}>Send</button>
                        </p>
                        */ }
                        <p>
                            <input style={{ width: '68%' }} className={inputs.standard_input} type="text" placeholder="Amount" />
                            <button style={{ width: '30%', marginLeft: '2%'}} className={` ${inputs.standard_button} ${inputs.wide} `}>Send</button>
                        </p>
                    </form>
                </div>

                <div className={`${styles.banking_view__column} ${transferStyles.deposit_request}`}>
                    <div>
                        <h1>Deposit request</h1>
                    </div>
                    <div className={transferStyles.qrcode}>
                        <img className={transferStyles.qrcode__image} src={mockDataMatrixCode} alt="QR code" />
                        <button className={inputs.standard_button} > Copy request link </button>
                    </div>
                </div>
                
            </div>
        </div>
    );
}

export default Transfers;
