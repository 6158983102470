import React from 'react';
import ReactDOM from 'react-dom/client';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// Components
import Landing from './components/Landing';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import Banking from './components/desktop/Banking';
import Transfers from './components/desktop/Transfers';
import Emission from './components/desktop/Emission';
import Account from './components/desktop/Account';
import Chats from './components/desktop/Chats';
import Blink from './components/desktop/Blink';
import History from './components/desktop/History';

import DesktopApp from './components/desktop/DesktopApp';
import MobileApp from './components/mobile/MobileApp';

// Styles
import './stylesheets/global/global.scss';
import './stylesheets/global/scrollbar.scss';


const router = createBrowserRouter([
  {
    path: '/',
    children: [
      {
        path: '',
        element: <Landing />,
      },
      {
        path: '/sign-in',
        element: <SignIn />,
      },
      {
        path: '/sign-up',
        element: <SignUp />,
      },
      {
        path: '/panel',
        element: <DesktopApp />,
        children: [
          {
            path: '',
            element: <Banking />,
          },
          {
            path: 'transfer',
            element: <Transfers />,
          },
          {
            path: 'emission',
            element: <Emission />,
          },
          {
            path: 'account',
            element: <Account />,
          },
          {
            path: 'chats',
            element: <Chats />,
          },
          {
            path: 'blink',
            element: <Blink />,
          },
          {
            path: 'history',
            element: <History />,
          },
        ]
      },
      {
        path: '/app',
        element: <MobileApp />,
      }
    ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);
