import { Outlet, Link, useNavigate } from 'react-router-dom';

// Stylesheet
import styles from '../../stylesheets/desktop/desktopLayout.module.scss';
import bgAnimations from '../../stylesheets/landing/backgroundAnimation.module.scss';


function MenuLogo() {
    return (
        <div className={styles.desktop_app__menu_logo}>
            <img src="/graphics/EcoBankLogo.webp" alt="logo" />
        </div>
    )
}

function MenuElement(props) {
    return (
        <Link to={props.path} >
            <div className={styles.desktop_app__menu_element}>
                <div className={styles.desktop_app__menu_icon}>
                    <span className="material-symbols-outlined"> { props.icon} </span>
                </div>
                <div>
                    { props.title }
                </div>
            </div>
        </Link>
    );
}

function DesktopApp() {
    const navigate = useNavigate();
    const orbs = Array.from({ length: 27 }, (_, index) => index + 1);
    return (
        <div className={styles.desktop_app}>
           <div className={styles.desktop_app__menu}>
                <div>
                    <MenuLogo />
                    <MenuElement path="/panel" title="Banking" icon="account_balance" />
                    <MenuElement path="/panel/blink" title="ecoBLINK" icon="contactless" />
                    <MenuElement path="/panel/transfer" title="Transfer" icon="swap_horiz" />
                    <MenuElement path="/panel/history" title="History" icon="history" />
                    <MenuElement path="/panel/emission" title="Emission" icon="eco" />
                    <MenuElement path="/panel/account" title="Account" icon="person" />
                    <MenuElement path="/panel/chats" title="Chats" icon="group" />
                </div>
                <div className={styles.desktop_app__account}>
                    <div className={styles.desktop_app__account_avatar}>
                        <img src="https://i.pravatar.cc/150?img=12" alt="avatar" />
                    </div>
                    <div className={styles.desktop_app__account_name}>
                        John Doe
                    </div>
                    <div className={styles.desktop_app__account__logout}>
                        <span onClick={() => navigate('/')} className="material-symbols-outlined"> logout </span>
                    </div>
                </div>
           </div>
           <div className={styles.desktop_app__content}>
                <div className={bgAnimations.background}>
                    {orbs.map((index) => (
                        <span key={index}></span>
                    ))}
                </div>
                <Outlet />
           </div>
        </div>
    );
}

export default DesktopApp;
